<template>
  <v-app>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4" class="my-auto">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Forgot Password</div>
          </template>
          <v-container fluid>
            <v-text-field
              v-model="data.email"
              name="email"
              :rules="[rules.required]"
              label="E-mail"
            ></v-text-field>
            <v-text-field
              v-model="data.password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min_8]"
              :type="show ? 'text' : 'password'"
              name="new password"
              label="Password"
              hint="At least 8 characters"
              @click:append="show = !show"
            ></v-text-field>
            <v-text-field
              v-model="data.password_confirmation"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.same_as_password(data.password)]"
              :type="show ? 'text' : 'password'"
              name="password confermation"
              label="Password"
              hint="At least 8 characters"
              @click:append="show = !show"
            ></v-text-field>

            <v-col class="text-right">
              <v-btn
                class="mr-4 mt-4"
                @click="resetPassword(data)"
                color="primary"
              >
                {{ $t("reset_password") }}
              </v-btn>
            </v-col>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
export default {
  mixins: [validationRules],
  name: "ResetPassword",
  data() {
    return {
      data: {},
      show: false,
      valid: false,
    };
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },

  methods: {
    async resetPassword(data) {
      data.token = this.$route.params.token;

      try {
        await this.$store.dispatch("resetPassword", data);
        this.$store.dispatch(
          "alerts/success",
          this.$t("password_updated_successfully")
        );
        this.$router.push({ name: "login" });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
